<template>
    <div class="invitation">
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                    <van-cell-group>
                        <van-cell v-for="item in list" :key="item.id" :title="item.memberName"/>
                    </van-cell-group>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
        </van-pull-refresh>
        <no-data v-if="showData"/>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data () {
        return {
            api: 'member/base/member/member-list',
            model: {},
        };
    },
    methods: {
        init() {
            this.initList()
        }
    },
    mounted() {
        this.model.inviteeId = this.$route.query.memberId
        this.init()
    },
}
</script>
<style lang='scss'>

</style>